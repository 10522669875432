import React, { useEffect, useState } from 'react'
import axios from 'axios';
const TermsAndConditions = () => {
    const [terms ,setTerms] = useState("");
    const getTerm = () => {
     let url = process.env.REACT_APP_BASEURL + `content/terms-and-conditions/`;
       axios.get(url).then((res) =>{
        console.log("res",res.data);
           setTerms(res.data)
       })
       .catch((err) =>{
        console.log("error",err);
       })
    };
    useEffect(() => {
        getTerm();
    },[]);
    return (
        <div>
            <div className='container'>
                <div className='row text-center'>
                    <h2>{terms.title ? terms.title : "N/A"}</h2>
                    <div className='col'>
                        <p>{terms.content ? terms.content : "N/A"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions