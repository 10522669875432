import React, { useEffect, useState } from 'react'
import axios from 'axios';
const AboutUs = () => {
  const [aboutus, setAboutus] = useState("");
  const getAboutUs = () => {
    let url = process.env.REACT_APP_BASEURL + `content/about/`;
    axios.get(url).then((res) => {
      console.log("res", res.data);
      setAboutus(res.data)
    })
      .catch((err) => {
        console.log("error", err);
      })
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  return (
    <div>
          <div className='container'>
              <div className='row text-center'>
                  <h2>{aboutus.title ? aboutus.title : "N/A"}</h2>
                  <div className='col'>
                     <p>{aboutus.content ? aboutus.content : "N/A"}</p>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default AboutUs