import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { ToastContainer , toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Careers = () => {
    let navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    let [image, setImage] = useState("");
    let [sendImage, setSendImage] = useState();
    let imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader.result);
            }
            setSendImage(e.target.files[0]);
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const onSubmit = (data) => {
        // console.log("hh",window.location.host)
        let formData = new FormData();
        formData.append("fullname", data.name);
        formData.append("email", data.email);
        formData.append("file", sendImage)

        let url = process.env.REACT_APP_BASEURL + `api/career/`;
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json",
            },
        };
        axios.post(url, formData, config).then((res) => {
            console.log(res.data);
             if(res.data){
                 toast.success(res.data.message, {
                     position: toast.POSITION.TOP_RIGHT,
                     autoClose: 3000,
                     theme: "colored",
                 });
                //  navigate('/')
             }
        }).catch((err) => {
            console.log(err);
            if(err){
                toast.error("Fields may be empty or filled incorrect.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    theme: "colored",
                });
                
            }
        })
    }
    return (
        <div>
            <ToastContainer />
            <div className='container'>
                <div className='row mt-5'>
                    <div className='col mb-5'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='card border-0 shadow pb-3'>
                                <h2 className='text-center mt-5'>Careers</h2>
                                <div className='card-body'>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Full Name<span className='text-danger'>*</span></label>
                                        <input type="text" name="name" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Full Name"
                                            {...register("name", {
                                                required: true,
                                                pattern: /^[A-Za-z]+$/i
                                            })} />
                                        {errors?.name?.type === "required" && (<p className="text-danger">This field is required</p>)}
                                        {errors?.name?.type === "pattern" && (<p className="text-danger">Alphabetical characters only</p>)}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Email address<span className='text-danger'>*</span></label>
                                        <input type="email" name="email" className="form-control form-control-lg" id="exampleFormControlInput1" placeholder="Email Address"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            })} />
                                        {errors?.email?.type === "required" && (
                                            <p className="text-danger">This field is required</p>
                                        )}
                                        {errors?.email?.type === "pattern" && (
                                            <p className="text-danger">Invalid email address</p>
                                        )}
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor="formFileLg" className="form-label">Upload CV<span className='text-danger'>*</span></label>
                                        <input onChange={imageHandler} accept="pdf*" className="form-control form-control-lg" id="formFileLg" type="file" />
                                    </div>
                                    <div className='text-center mt-5'>
                                        <button type="submit" className="btn btn-primary btn-lg px-5">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Careers