import React from 'react'
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../animationData.json';
const PageNotFound = () => {
    let navigate = useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className='container-fluid bgcol' style={{height:"100vh", overflow:'hidden'}}>
                <div className='row'>
                    <div className='col'>
                        <Lottie options={defaultOptions} height='62%' />
                        <div className='text-center mt-0 pt-0'>
                            <button onClick={() => { navigate("/") }} className='btn btn-lg text-white px-5' style={{ backgroundColor: "#FC8019" }}>Back to Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound