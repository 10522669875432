import { Navigate } from "react-router-dom";
import AboutUs from "../AboutUs";
import Careers from "../Careers";
import CommonLandingPage from "../CommonLandingPage";
import FullLayout from "../layout/FullLayout";
import { FullLayout404 } from "../layout/FullLayout404";
import PageNotFound from "../PageNotFound";
import Privacypolicy from "../Privacypolicy";
import TermsAndConditions from "../TermsAndConditions";

const Themeroutes=[
    {
        path:'/',
        element:<FullLayout/>,
        children:[
            {path:'/', element: <Navigate to='/home'/> },
            {path:'/home', element:<CommonLandingPage/>},
            { path: '/privacy', element: <Privacypolicy /> },
            { path: '/terms', element: <TermsAndConditions /> },
            { path: '/aboutus', element: <AboutUs /> },
            { path: '/careers', element: <Careers /> },
        ]
    },
    {
        path: '/',
        element: <FullLayout404 />,
        children: [
            { path: '*', element:<PageNotFound/> },
          
        ]
    },

]
export default Themeroutes;