import React from 'react'
import google from '../assets/Google Play Button.png'
import appstore from '../assets/App Store Button.png'
import head from '../assets/001.png'
const Header = () => {
    return (
        <div>
            <div className="fullscreen-landing">
                <div className="overlay">
                    <div className='container py-5'>
                        <div className="row text-white">
                            <div className='col-lg-6 col-md-6 pt-5'>
                                <h5 className="display-4 fw-normal"><b>Currently Delivering <br /> in <span className='colbg'>Haridwar.</span></b></h5>
                                <p className="display-6 fw-bold">Coming Soon to <span className='colbg'>Your City...</span></p>
                                <div className='row text-center'>
                                    <div className='col-12 col-lg-12 col-md-12 mt-5'>
                                        <div className='h3'>Download App</div>
                                        <div><img src={google} className='img-fluid m-1' width={150} height={100} /><img src={appstore} className='img-fluid m-1' width={150} height={100} /></div>
                                        <div className='small fw-bold ps-5 '><span className='position-absolute ps-5'>Coming soon...</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 pt-5'>
                                <img src={head} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header