import React from 'react'
import logo from "../assets/Abouts Logo.png";
import googleplay from "../assets/Google Play Button.png";
import appstore from "../assets/App Store Button.png";
// import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import {
    TiSocialLinkedin,
    TiSocialFacebook,
    TiSocialTwitter,
} from "react-icons/ti";
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
    let navigate = useNavigate();

  return (
    <>
    <div>
          <div className="footerbg pt-5">
              <div className="container mb-0 ">
                  <div className="row justify-content-lg-between">
                      <div className="col-6 col-sm-4 col-lg-auto pt-5">
                        <Link to={'/'}>
                          <img
                              src={logo} alt=""
                              className="img-fluid"
                              style={{ width: "150px" }} 
                          />
                          </Link>
                          <h6 className="fw-normal text-white">
                             Aforro Groceries Pvt. Ltd.
                          </h6>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-auto mb-3">
                          <h5 className="fw-bold mb-3 colbg">
                              Aforro
                          </h5>
                          <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                              <li className="mb-2 arrow"  onClick={() => {navigate("/privacy")}}>
                                     Privacy Policy
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/terms")}}>
                                      Terms & Conditions
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/aboutus")}}>
                                      About Us
                              </li>
                              <li className="mb-2 arrow"  onClick={() => {navigate("/careers")}}>
                                      Careers
                              </li>
                          </ul>
                      </div>
                      <div className="col-6 col-sm-4 col-lg-auto mb-3">
                          <h5 className="fw-bold mb-3 colbg">
                              Follow
                          </h5>
                          <ul className="list-unstyled mb-md-4 mb-lg-0 text-white">
                              <li className="mb-2 arrow">
                                <FaInstagram className="fs-5 border rounded-pill p-1 border-warning me-2" />Instagram
                              </li>
                              <li className="mb-2 arrow">
                                 <BsYoutube className="fs-5 border rounded-pill p-1 border-warning me-2" />Youtube
                              </li>
                              <li className="mb-2 arrow">
                                <TiSocialFacebook className="fs-4 border rounded-pill p-1 border-warning me-2" />Facebook
                              </li>
                              <li className="mb-2 arrow">
                                <TiSocialTwitter className="fs-4 border rounded-pill p-1 border-warning me-2" />Twitter
                              </li>
                              <li className="mb-2 arrow">
                                 <TiSocialLinkedin className="fs-4 border rounded-pill p-1 border-warning me-2" />Linkedin
                              </li>
                          </ul>
                      </div>
                      <div className="col-6 col-sm-4 col-md-12 col-lg-auto mb-3">
                        <div className='row text-center'>
                            <div className='col mt-5 text-white'>
                                <div className='small fw-bold mb-2 fs-5'>Download The App Now.</div>
                                <div><img src={googleplay} className='img-fluid m-1' width={150} height={100} /><img src={appstore} className='img-fluid m-1' width={150} height={100} /></div>
                                <div className='small fw-bold text-end'><span>Coming soon...</span></div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>             
          </div>
           <div className='copyright'>
              <div className="container text-white py-4">
                  <div className="row">
                      <div className="col text-center">
                          <small>Copyright ©aforro Groceries 2023 All rights reserved</small>
                      </div>
                  </div>
              </div>
           </div> 
    </div>
      </>
  )
}

export default Footer